<template>
  <div class="map-container">
    <Map ref="map" :single-click="singleClick" :layer-menu="layerMenu" v-if="loadMap" />
  </div>
</template>

<script>
import Map from '@/components/Map/map.vue'
import { setToken, getToken } from '@/utils/auth'

export default {
  name: 'MapView',
  components: {
    Map
  },
  data() {
    return {
      layerMenu: [],
      singleClick: null,
      loadMap: false
    }
  },
  created() {
    // Extract token from URL if present
    const token = this.getTokenFromUrl()
    if (token) {
      // Set token in cookies
      setToken(token)
    }
    
    // Set up message listener for cross-domain communication
    this.setupMessageListener()
    
    // Load layer menu data from API
    this.loadLayerMenuData()
  },
  mounted() {
    // When embedded in an iframe, notify parent that we're ready
    if (window.parent !== window) {
      try {
        window.parent.postMessage({ type: 'MAP_READY' }, '*')
      } catch (e) {
        console.error('Failed to communicate with parent:', e)
      }
    }
  },
  methods: {
    setupMessageListener() {
      // Listen for messages from parent window (for iframe embedding)
      window.addEventListener('message', (event) => {
        // Allow messages from trusted domains
        if (event.origin === 'http://smartsite-cloud.shipzz.com' || 
            event.origin === window.location.origin) {
          
          console.log('Received message:', event.data)
          
          // Handle token setting
          if (event.data.type === 'SET_TOKEN' && event.data.token) {
            console.log('Received token from parent')
            setToken(event.data.token)
            
            // Send acknowledgment back to parent
            try {
              window.parent.postMessage({
                type: 'TOKEN_ACCEPTED',
                success: true
              }, event.origin)
            } catch (e) {
              console.error('Failed to send token acceptance:', e)
            }
            
            // Reload layer menu data with new token
            this.loadLayerMenuData()
          }
        }
      })
    },
    getTokenFromUrl() {
      // Parse token from URL query parameters
      let queryString = window.location.hash.split('?')[1]
      if (!queryString && window.location.search) {
        // Also check regular query parameters
        queryString = window.location.search.substring(1)
      }
      
      if (queryString) {
        const params = new URLSearchParams(queryString)
        return params.get('token')
      }
      return null
    },
    loadLayerMenuData() {
      // Check if we have a token
      if (!getToken()) {
        console.warn('No token available for API request')
        // Request token from parent if in iframe
        if (window.parent !== window) {
          try {
            window.parent.postMessage({ type: 'REQUEST_TOKEN' }, '*')
          } catch (e) {
            console.error('Failed to request token from parent:', e)
          }
        }
      }
      
      // Fetch layer menu configuration from API
      this.$get('menu/getLayerMenu').then(res => {
        // Fix layer visibility settings if needed
        if (res.data && Array.isArray(res.data)) {
          // Process the response to ensure consistency for specific layers
          // This ensures the UI state matches the actual layer visibility
          this.layerMenu = res.data.map(layer => {
            // For specific layers, ensure they are visible (isShow: 1)
            if (['航道', '航标', '锚地'].includes(layer.name)) {
              return { ...layer, isShow: 1 }; // Override to ensure visibility
            }
            return layer;
          });
        } else {
          this.layerMenu = res.data;
        }
        this.loadMap = true;
      }).catch(err => {
        console.error('Failed to load layer menu:', err)
        // Fallback to default configuration if API fails
        this.layerMenu = [
          { name: '卫星', url: 'baseLayer', icon: 'weixingtu', isShow: 1 },
          { name: '航道', url: 'ChannelLayer', icon: 'hangdao', isShow: 1 },
          { name: '航标', url: 'markerLayer', icon: 'hangbiao', isShow: 1 },
          { name: '锚地', url: 'anchorLayer', icon: 'maodi', isShow: 1 },
          { name: 'AIS', url: 'shipLayer', icon: 'diqiu-copy', isShow: 1 },
          { name: '工程', url: 'DxfLayer', icon: 'gongcheng', isShow: 1 },
          { name: '视频', url: 'cameraLayer', icon: 'shexiangtou', isShow: 1 },
          { name: '标注', url: 'areaVector', icon: 'duobianxing', isShow: 1 },
          { name: '船舶', url: 'customShipLayer', icon: 'chuanbo', isShow: 1 },
          { name: '围栏', url: 'enclosureLayer', icon: 'dianziweilan', isShow: 1 },
          { name: '报警', url: 'alarmVector', icon: 'weixianxinhao', isShow: 1 }
        ]
        this.loadMap = true
      })
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
</style> 